function stringifyBreadcrumbs(breadcrumbs) {

  if (breadcrumbs.length == 0) {
    return JSON.stringify(breadcrumbs)
  }
  // get the last item of breadcrumbs array
  const lastItem = breadcrumbs[breadcrumbs.length - 1]

  if (!lastItem || !lastItem.hasOwnProperty('to')) {
    return JSON.stringify(breadcrumbs)
  }

  // get the to property of the last item
  const to = lastItem.to

  const arr = typecastArrayValues(to)
  return JSON.stringify(arr)
}

function typecastArrayValues(input) {
  if (Array.isArray(input)) {
    return input.map(element => {
      if (typeof element === 'object') {
        return typecastArrayValues(element);
      } else if (typeof element === 'string' && !isNaN(element)) {
        return parseInt(element, 10);
      } else {
        return element;
      }
    });
  } else if (typeof input === 'object') {
    return Object.fromEntries(
      Object.entries(input)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .map(([key, value]) => {
          if (typeof value === 'object') {
            return [key, typecastArrayValues(value)];
          } else if (typeof value === 'string' && !isNaN(value)) {
            return [key, parseInt(value, 10)];
          } else {
            return [key, value];
          }
        })
    );
  } else {
    return input;
  }
}

export const state = () => ({
  breadcrumbs: [],
  breadcrumbEntryTime: 0,
  previousBreadcrumbs: [],
  lastActivity: 0,
  drawer: null,
  selectedEntityItems: [],

  usersOnline: [],
  websocket: false,
  isConnected: false
});

export const getters = {

  isSelectedEntityItem: (state) => (entity) => {
    return state.selectedEntityItems.findIndex(item => item.entityId == entity.entityId) == -1
  },

  hasBreadcrumbExceptUser: (state) => (userId) => {

    let hasMatch = false
    const entries = state.usersOnline.filter(item => item.id != userId)
    const stringBreadcrumbs = stringifyBreadcrumbs(state.breadcrumbs)

    entries.every(user => {
      if (stringBreadcrumbs == stringifyBreadcrumbs(user.path)) {
        if (user.entry_time < state.breadcrumbEntryTime) {
          hasMatch = user
          return false
        }
      }

      return true
    })

    return hasMatch
  },

  isUserActive: (state) => (userId) => {
    const user = state.usersOnline.find(item =>  item.id == userId)
    if (user) {
      return user.is_active
    }
    return false;
  },

  isWebsocketConnected: (state) => {
    // 0 = connecting
    // 1 = open
    // 2 = closing
    // 3 = closed
    return state.isConnected && state.websocket?.readyState == 1
  }
}

export const mutations = {
  breadcrumbs(state, breadcrumbs) {
    const time = parseInt(Date.now()/1000);
    state.lastActivity = time
    state.breadcrumbEntryTime = time
    if (state.isConnected) {
      const payload = {
        path: breadcrumbs,
        last_activity: time,
        entry_time: time
      }
      try {
        state.websocket.send(JSON.stringify(payload))
      } catch (e) {
        state.isConnected = false
      }
    }
    state.previousBreadcrumbs = breadcrumbs
    state.breadcrumbs = breadcrumbs
  },

  drawer(state, value) {
    state.drawer = value
  },

  toggleDrawer(state) {
    state.drawer = !state.drawer
  },

  toggleSelectedEntityItem(state, entity) {
    const index = state.selectedEntityItems.findIndex(item => item.entityId == entity.entityId)
    if (index == -1) {
      state.selectedEntityItems.push(entity)
    } else {
      state.selectedEntityItems.splice(index, 1)
    }
  },
  clearSelectedEntityItem(state) {
    state.selectedEntityItems = []
  },

  setUsersOnline(state, users) {
    state.usersOnline = users.map(item => {
      const diff = parseInt(Date.now()/1000 - item.last_activity)
      item.is_active = diff <= 60*10 // 10min
      return item
    })
  },

  setActivity(state) {
    state.lastActivity = parseInt(Date.now()/1000)
  },

  setWebsocket(state, websocket) {
    state.websocket = websocket
  },

  isConnected(state, isConnected) {
    state.isConnected = isConnected
  },
}

export const actions = {

  sendStaleActivity(context) {
    if (context.state.isConnected) {
      const payload = {
        path: context.state.breadcrumbs,
        last_activity: context.state.lastActivity
      }
      context.state.websocket.send(JSON.stringify(payload))
    }
  },

  async websocketUsersClose(context) {
    context.commit('isConnected', false)
    return new Promise(function(resolve, reject) {
      // we have never had an active connection,
      // mabye failed to connect WS, therefore
      // resolve the promise
      if (context.state.websocket === false) {
        resolve()
      }

      context.state.websocket.onclose = () => {
        resolve();
      }

      context.state.websocket.close()

      if (context.state.websocket?.readyState == 3) {
        resolve();
      }
    });
  },

  async websocketUsersConnet(context, payload) {

    // TODO:
    // if someone is on the same site, and texting log texts, like blogs we should track:
    //
    // $scope.lastKeyStroke = Date.now();
    // window.addEventListener('keyup', function(ev) {
    // ...
    // }
    //
    // If there was a NO key stroke for 1 minute, we should send (sendData) this information
    // to all other people
    // this means we should also send a timestamp along with the sendData f.e
    // last_keystroke = ...
    // so thesocket server can evaluate also this information about last activity

    const { orgId, server } = payload

    const userId = this.$auth.user?.id || false
    const token = this.$auth.wssToken || false

    return new Promise(function(resolve) {

      if (!userId || !token) {
        resolve();
      }

      if (!server || server == 0) {
        resolve();
      }

      // if we already have a connection, resolve the promise
      if (state.websocket?.readyState == 1) {
        resolve();
      }

      const serverUrl = `${server}/users?orgId=${orgId}&token=${token}&userId=${userId}`
      const ws = new WebSocket(serverUrl)
      ws.onopen = () => {
        context.commit('setWebsocket', ws)
        context.commit('isConnected', true)
        resolve();
      }

      ws.onerror = function() {
        context.commit('isConnected', false)
        resolve();
      };

      ws.onclose = function() {
        context.commit('isConnected', false)
        resolve();
      }

      ws.onmessage = data => {
        context.commit('setUsersOnline', JSON.parse(data.data))
        resolve();
      }

      window.addEventListener("unload", function () {
        if(ws?.readyState == WebSocket.OPEN) {
          ws.close();
        }
        resolve();
      });
    })
  }
}
