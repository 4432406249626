export default async function (context) {

  // check for an active challange in the local storage
  if (context.store.state.authStorage.challenge) {
    await context.store.dispatch('authStorage/ensureChallenge')

    // redirect to auth switcher if a challange exists
    if (context.store.getters['authStorage/isAuthenticatedButNoActiveOrganisation']) {
      return context.redirect({
        name: 'auth-switcher'
      })
    }

    // if the session is valid, redirect to index as the user is authenticated
    if (context.store.getters['authStorage/isAuthenticatedOrganisationSession']) {
      return context.redirect({
        name: 'index'
      })
    }
  }
}
