export const state = () => ({
  data: [],
});

export const mutations = {
  SET_DATA(context, data) {
    context.data = data;
  }
}

export const actions = {
  async fetch (context) {
    // add option to force but if there are tags this should not be loaded anymore
    const response = await this.$axios('organisation/tags?fields=id,alias,alias_natural,color,hint');
    response.data.map(item => {
      item.id = parseInt(item.id)
      item.is_visible = true;
      return item
    })
    context.commit('SET_DATA', response.data);
  }
}
