export const state = () => ({
  title: null,
  closeOnly: false,
  id: null,
  is_done: false,
  is_reserved: false,
  is_waiting: false,
  is_error: false,
  route: {},
  entityId: false,
  entityType: false,
  active: false,
  count: 0
});

export const mutations = {
  start(context, payload) {
    context.id = payload.id
    context.title = payload.title
    context.buttonlabel = payload.buttonlabel
    context.route = payload.route
    context.entityId = payload.entityId
    context.entityType = payload.entityType
    context.closeOnly = payload.closeOnly
    context.active = true
    context.is_error = false
    context.count = 0
  },
  update(context, payload) {
    context.is_done = payload.is_done
    context.is_reserved = payload.is_reserved
    context.is_waiting = payload.is_waiting
    context.is_error = payload.is_error
    context.count = payload.count
  },
  stop(context) {
    context.active = false
    context.is_done = false
    context.is_error = false
    context.count = 0
  }
}

export const actions = {
  start (context, payLoad) {
    let object = {
      title: payLoad.title,
      id: payLoad.id,
      route: payLoad.route,
      buttonlabel: payLoad.hasOwnProperty('buttonlabel') ? payLoad.buttonlabel : false,
      entityId: payLoad.hasOwnProperty('entityId') ? payLoad.entityId : false,
      entityType: payLoad.hasOwnProperty('entityType') ? payLoad.entityType : false,
      closeOnly: payLoad.hasOwnProperty('closeOnly') ? payLoad.closeOnly : false
    }
    context.commit('start', object)
    context.dispatch('run', payLoad.id)
  },
  run (context) {
    setTimeout(() => {
      context.dispatch('poll')
    }, 2000)
  },
  poll(context) {
    this.$axios.get('queue/status/' + context.state.id, {progress: false}).then(response => {

      if (context.state.count >= 30) {
        // force error after 30 trys
        return context.commit('update', {
          is_done: true,
          is_reserved: false,
          is_waiting: false,
          is_error: true,
          count: context.state.count + 1
        })
      }
      context.commit('update', {
        is_done: response.data.is_done,
        is_reserved: response.data.is_reserved,
        is_waiting: response.data.is_waiting,
        is_error: false,
        count: context.state.count + 1
      })

      if (!response.data.is_done) {
        context.dispatch('run')
      }

    }).catch(error => {
      context.commit('update', {
        is_done: true,
        is_reserved: false,
        is_waiting: false,
        is_error: true,
        count: context.state.count + 1
      })
    })
  }
}
